import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import API from '../api/api';
import { Text, Title, Button, Box } from '../components/Core';
import Section from '../components/Section';
import { Container, Col, Row } from 'react-bootstrap';
import { getProperty } from '../utils/helperFn';
import { navigate } from 'gatsby';
import GlobalContext from '../context/GlobalContext';
import FullScreenLoader from '../components/FullScreenLoader';
import { AgreementSwitch } from '../components/Core/Switch';
import Loader from '../assets/image/gif/loader.gif';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';

const SchedulePage = (props) => {
  const appointmentsSchema = {
    availableDates: [],
    selectedDate: null,
    startDay: undefined,
    endDay: undefined,
    defaultStartDate: '',
  };

  const gContext = useContext(GlobalContext);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [addressId, setAddressId] = useState(false);
  let [appointmentsProps, setAppointmentsData] = useState(appointmentsSchema);
  let [calendarFormat, setCalendarFormat] = useState('week');
  let woId = '';

  useEffect(() => {
    /* We redirect user to home page if no workOrderId provided in URL */
    if (!new URLSearchParams(props.location.search).get('woId')) {
      gContext.hideFullScreenLoader();
      navigate('/');
    }
  }, []);

  useEffect(() => {
    gContext.showFullScreenLoader();
    woId = new URLSearchParams(props.location.search).get('woId');
    if (appointmentsProps.availableDates.length === 0) {
      getWorkOrder(woId);
    } else {
      gContext.hideFullScreenLoader();
    }
  }, []);

  function returnDateRange(startDate, backStep, inlineAddressId) {
    let date = '';

    try {
      if (startDate === undefined) {
        date = moment(new Date()).format('YYYY-MM-DD');
      } else {
        date = startDate;
      }

      setCalendarLoading(true);
      gContext.setQuoteLoading(true)
      API.get(
        `/FieldServiceModule/v1.0/ServiceAppointment/calendar?start=${date}&end=${formatDate(
          addDaysToDate(date, 7),
        )}&type=INSTALL&addressId=${inlineAddressId ? inlineAddressId : addressId}`,
      )
        .then((resp) => {
          const firstDate = resp.data.data.find((item) => {
            if (getProperty(item, 'AM') === true || getProperty(item, 'PM') === true) {
              return item;
            }
          });
          if (calendarFormat === 'day') {
            if (firstDate !== undefined && appointmentsProps.selectedDate === null) {
              if (backStep) {
                if (
                  moment(appointmentsProps.availableDates?.[0]?.Date).toDate().getTime() >
                  moment(appointmentsProps.defaultStartDate).toDate().getTime()
                ) {
                  setAppointmentsData({
                    ...appointmentsProps,
                    availableDates: resp.data.data,
                    selectedDate: {
                      Date: formatDate(firstDate.Date),
                      TimeBlock: firstDate.AM ? 'AM' : 'PM',
                      Type: 'INSTALL',
                      skipCustomerNotification: true,
                      scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                    },
                    startDay: resp.data.data.length - 1,
                    endDay: resp.data.data.length,
                    defaultStartDate:
                      appointmentsProps.defaultStartDate === ''
                        ? resp.data.data[0]?.Date
                        : appointmentsProps.defaultStartDate,
                  });
                } else {
                  setAppointmentsData({
                    ...appointmentsProps,
                    availableDates: resp.data.data,
                    selectedDate: {
                      Date: formatDate(firstDate.Date),
                      TimeBlock: firstDate.AM ? 'AM' : 'PM',
                      Type: 'INSTALL',
                      skipCustomerNotification: true,
                      scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                    },
                    startDay: 0,
                    endDay: 1,
                    defaultStartDate:
                      appointmentsProps.defaultStartDate === ''
                        ? resp.data.data[0]?.Date
                        : appointmentsProps.defaultStartDate,
                  });
                }
              } else {
                setAppointmentsData({
                  ...appointmentsProps,
                  scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                  availableDates: resp.data.data,
                  selectedDate: {
                    Date: formatDate(firstDate.Date),
                    TimeBlock: firstDate.AM ? 'AM' : 'PM',
                    Type: 'INSTALL',
                    skipCustomerNotification: true,
                    scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                  },
                  startDay: 0,
                  endDay: 1,
                  defaultStartDate:
                    appointmentsProps.defaultStartDate === ''
                      ? resp.data.data[0]?.Date
                      : appointmentsProps.defaultStartDate,
                });
              }
            } else {
              if (backStep) {
                if (
                  moment(appointmentsProps.availableDates?.[0]?.Date).toDate().getTime() >
                  moment(appointmentsProps.defaultStartDate).toDate().getTime()
                ) {
                  setAppointmentsData({
                    ...appointmentsProps,
                    availableDates: resp.data.data,
                    startDay: resp.data.data.length - 1,
                    endDay: resp.data.data.length,
                    defaultStartDate:
                      appointmentsProps.defaultStartDate === ''
                        ? resp.data.data[0]?.Date
                        : appointmentsProps.defaultStartDate,
                  });
                } else {
                  setAppointmentsData({
                    ...appointmentsProps,
                    availableDates: resp.data.data,
                    startDay: 0,
                    endDay: 1,
                    defaultStartDate:
                      appointmentsProps.defaultStartDate === ''
                        ? resp.data.data[0]?.Date
                        : appointmentsProps.defaultStartDate,
                  });
                }
              } else {
                setAppointmentsData({
                  ...appointmentsProps,
                  availableDates: resp.data.data,
                  startDay: 0,
                  endDay: 1,
                  defaultStartDate:
                    appointmentsProps.defaultStartDate === ''
                      ? resp.data.data[0]?.Date
                      : appointmentsProps.defaultStartDate,
                });
              }
            }
          } else {
            if (firstDate !== undefined && appointmentsProps.selectedDate === null) {
              setAppointmentsData({
                ...appointmentsProps,
                scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                availableDates: resp.data.data,
                selectedDate: {
                  Date: formatDate(firstDate.Date),
                  TimeBlock: firstDate.AM ? 'AM' : 'PM',
                  Type: 'INSTALL',
                  skipCustomerNotification: true,
                  scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                },
                defaultStartDate:
                  appointmentsProps.defaultStartDate === ''
                    ? resp.data.data[0]?.Date
                    : appointmentsProps.defaultStartDate,
              });
            } else {
              if (resp.data.data.length > 0) {
                appointmentsProps.availableDates = resp.data.data;

                setAppointmentsData({
                  ...appointmentsProps,
                  scheduleId: firstDate.AM ? firstDate.AMConfig.id : firstDate.PMConfig.id,
                  defaultStartDate:
                    appointmentsProps.defaultStartDate === ''
                      ? resp.data.data[0]?.Date
                      : appointmentsProps.defaultStartDate,
                });
              }
            }
          }

          setCalendarLoading(false);
          gContext.setQuoteLoading(false)
        })
        .catch((error) => {
          setCalendarLoading(false);
          gContext.setQuoteLoading(false)
          gContext.setAPIErrorMsg({
            title: 'Error',
            message: error.response ? error.response?.data.message : error
          });
        });
    } catch (e) {
      setCalendarLoading(false);
    }
  }

  const getWorkOrder = async (woId) => {
    if (window.innerWidth < 991) setCalendarFormat('day');
    if (woId) {
      await API.get(`/FieldServiceModule/v1.0/db/WorkOrder/${woId}?&withLinks=true`)
        .then((resp) => {
          const address = resp.data.data.links.find(
            (associatedRecord) => associatedRecord.entity === 'CrmModule:Address',
          );

          gContext.hideFullScreenLoader();

          if (address) {
            let addressID = address.id;
            setAddressId(address.id);
            returnDateRange(undefined, undefined, addressID);
          } else {
            navigate('/');
          }
        })
        .catch((error) => {
          gContext.hideFullScreenLoader();
          gContext.setAPIErrorMsg({
            title: 'Error',
            message: error.response ? error.response?.data.message : error
          });
        });
    }
  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const addDaysToDate = (startDate, days) => {
    if (typeof startDate === 'string') {
      startDate = moment(startDate).toDate();
    }
    return startDate.setDate(startDate.getDate() + days);
  };

  const LegalSwitchCard = ({ color = 'primary', text, children, ...rest }) => (
    <Box
      bg="light"
      border="1px solid"
      borderColor="border"
      borderRadius={10}
      className={`d-flex`}
      {...rest}
      css={`
        min-width: 100%;
        width: 100%;
        .legal-text {
          font-size: 1em;
          line-height: 1.5em;
        }
      `}
    >
      <Text className="legal-text pt-3 pl-0 pr-0 pl-lg-5 pr-0 pr-md-5">{text}</Text>
      {children}
    </Box>
  );

  const submitAppointment = (values) => {
    const woId = new URLSearchParams(props.location.search).get('woId');

    if (woId) {
      gContext.showFullScreenLoader();
      try {
        API.post(`FieldServiceModule/v1.0/ServiceAppointment/WorkOrder/${woId}/reserve`, {
          EarlierAvailability: getProperty(values, 'agreementSwitch'),
          Date: getProperty(appointmentsProps.selectedDate, 'Date'),
          TimeBlock: getProperty(appointmentsProps.selectedDate, 'TimeBlock'),
          scheduleId: getProperty(appointmentsProps.selectedDate, 'scheduleId'),
          properties: {
            RequestedBy: 'CUSTOMER',
            RescheduleReason: 'INSTALL_REMINDER_RESCHEDULED',
            Description:
              'Customer has requested to reschedule from their install reminder notification',
          },
        })
          .then((resp) => {
            gContext.hideFullScreenLoader();
            navigate(`/appointment-success?woId=${woId}/`);
          })
          .catch((error) => {
            gContext.hideFullScreenLoader();
            gContext.setAPIErrorMsg({
              title: 'Error',
              message: error.response ? error.response?.data.message : error
            });
          });
      } catch (e) {
        gContext.hideFullScreenLoader();
        console.log(e);
      }
    } else {
      gContext.hideFullScreenLoader();
      gContext.setAPIErrorMsg({
        title: 'Error',
        message: 'There was an error!',
      });
    }
  };

  function renderCalendar() {
    let availableDates = [];
    if (calendarFormat === 'day') {
      availableDates = appointmentsProps.availableDates.slice(
        appointmentsProps.startDay === undefined ? 0 : appointmentsProps.startDay,
        appointmentsProps.endDay === undefined ? 1 : appointmentsProps.endDay,
      );
    } else {
      availableDates = appointmentsProps.availableDates.slice(
        appointmentsProps.startDay === undefined ? 0 : appointmentsProps.startDay,
        appointmentsProps.endDay === undefined ? 7 : appointmentsProps.endDay,
      );
    }

    return (
      <div className="calendar-wrapper">
        {availableDates.map((res) => {
          return renderDate(res);
        })}
      </div>
    );
  }

  function renderDate(data) {
    return (
      <>
        <div className="date-wrapper" key={`${data.Date}_Wrapper`}>
          <div className="date-header mb-2" key={`${data.Date}_Header`}>
            {returnFormatedDate(data.Date)}
          </div>
          {customComponent(data)}
        </div>
      </>
    );
  }

  function returnFormatedDate(date) {
    const weekday = moment(date).format('ddd');
    const jsDate = moment(date).format('DD/MM');
    return weekday + ' ' + jsDate;
  }

  const customComponent = (event) => {
    return (
      <div
        key={`${event.Date}_ColumnWrapper`}
        className={
          'custom-time-column-wrapper' +
          ' ' +
          appointmentsProps.selectedDate?.TimeBlock +
          (moment(appointmentsProps.selectedDate?.Date).toDate().getTime() ===
          moment(event.Date).toDate().getTime()
            ? ' selected-button'
            : '')
        }
      >
        <Button
          key={`${event.Date}_AM`}
          size="sm"
          className="custom-select-time-button mb-3"
          onClick={() => timeClick('AM', event)}
          disabled={!getProperty(event, 'AM')}
          css={`
            font-size: 16px !important;
            min-width: 141px !important;
            height: 45px !important;
            width: 80%;
            margin: auto;
            border-radius: 8px;
          `}
        >
          AM
        </Button>
        <Button
          key={`${event.Date}_PM`}
          className="custom-select-time-button"
          onClick={() => timeClick('PM', event)}
          disabled={!getProperty(event, 'PM')}
          css={`
            font-size: 16px !important;
            min-width: 141px !important;
            height: 45px !important;
            width: 80%;
            margin: auto;
            border-radius: 8px;
          `}
        >
          PM
        </Button>
      </div>
    );
  };

  const timeClick = (ev, event) => {
    setAppointmentsData({
      ...appointmentsProps,
      selectedDate: {
        Date: formatDate(event.Date),
        TimeBlock: ev,
        Type: 'INSTALL',
        skipCustomerNotification: true,
        scheduleId: event.Config?.id,
        scheduleId: ev == 'AM' ? event.AMConfig.id : event.PMConfig.id,
      },
    });
  };

  function getDates(type) {
    if (calendarFormat === 'day') {
      if (type === 'next') {
        if (appointmentsProps.availableDates.length <= appointmentsProps.endDay) {
          returnDateRange(
            appointmentsProps.availableDates[appointmentsProps.availableDates.length - 1].Date,
          );
        } else {
          setAppointmentsData({
            ...appointmentsProps,
            startDay: appointmentsProps.startDay === undefined ? 1 : appointmentsProps.startDay + 1,
            endDay: appointmentsProps.endDay === undefined ? 2 : appointmentsProps.endDay + 1,
          });
        }
      } else {
        if (appointmentsProps.startDay === undefined) {
          return;
        } else if (appointmentsProps.startDay === 0) {
          let startDate = formatDate(addDaysToDate(appointmentsProps.availableDates[0].Date, -7));
          setTimeout(() => {
            returnDateRange(startDate, true);
          });
          return;
        } else {
          setAppointmentsData({
            ...appointmentsProps,
            startDay: appointmentsProps.startDay === 0 ? 0 : appointmentsProps.startDay - 1,
            endDay: appointmentsProps.endDay === 1 ? 1 : appointmentsProps.endDay - 1,
          });
        }
      }
    } else {
      if (type === 'next') {
        returnDateRange(
          appointmentsProps.availableDates[appointmentsProps.availableDates.length - 1].Date,
        );
      } else {
        let startDate = formatDate(addDaysToDate(appointmentsProps.availableDates[0].Date, -7));
        setTimeout(() => {
          returnDateRange(startDate);
        });
      }
    }
  }

  return (
    <>
      <FullScreenLoader title="Preparing appointment..." />
      <Section>
        <Container>
          <div className="appointments-header text-center mb-5">
            <Title>Schedule your installation</Title>
            <Text>and get connected to our ultra fast fibre network!</Text>
          </div>
          <div className="appointments-wrapper">
            <Row>
              <Col />
              <Col
                sm={12}
                className="mb-5 prev-next-wrapper text-center"
                css={`
                  justify-content: center;
                  display: flex;
                `}
              >
                <div className="mx-auto">
                  <Button className="change-week-button mr-3" onClick={() => getDates('prev')}>
                    &#8249; Previous {calendarFormat}
                  </Button>
                  <Button className="change-week-button" onClick={() => getDates('next')}>
                    Next {calendarFormat} &#8250;
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                sm={12}
                lg={6}
                className="mb-3"
                css={`
                  justify-content: center;
                  display: flex;
                `}
              >
                {calendarLoading ? (
                  <img src={Loader} alt="Loader" className="img-fluid mx-auto" />
                ) : (
                  renderCalendar()
                )}
              </Col>
            </Row>
            <Row className="pt-5 pb-3 text-center">
              <Col sm={12}>
                <p>* Actual date may differ.</p>
              </Col>
            </Row>

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ agreementSwitch: false }}
              onSubmit={(values) => {
                submitAppointment(values);
              }}
            >
              {({ values, errors, handleChange, setFieldValue, setFormData }) => (
                <Form>
                  <Row>
                    <Col sm={12} lg={12} className="mb-5 mt-3">
                      <LegalSwitchCard
                        text="Please contact me if an earlier appointment is available."
                        color="secondary"
                        className="p-4 text-center"
                      >
                        <div className="mt-4">
                          <AgreementSwitch
                            name="agreementSwitch"
                            value={values.agreementSwitch}
                            setFieldValue={setFieldValue}
                            style={{ verticalAlign: 'middle' }}
                          />
                          <span class="align-center">{values.agreementSwitch ? 'YES' : 'NO'}</span>
                        </div>
                      </LegalSwitchCard>
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col sm={12} lg={6} className="mb-3 text-center">
                      <Button
                        style={{
                          width: isMobile ? '90%' : '30%',
                          borderRadius: 50,
                          fontSize: '1em',
                          color: 'white',
                          padding: '15px 30px',
                          backgroundColor: '#07131E',
                          borderColor: '#07131E',
                        }}
                        type="submit"
                        className="mx-auto"
                        disabled={appointmentsProps.selectedDate === null}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Section>
    </>
  );
};
export default SchedulePage;
